.slideshow {
    position: fixed;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .slideshow-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%; 
    height: 80%;/* Scale to 80% of the container width */
    background-color: rgba(25, 24, 24, 0.7);
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
  }
  
  .slide-control {
    cursor: pointer;
    font-size: 30px;
  }
  
  .slide {
    max-width: 80%;
    height: 100%;
  }
  .image-list-container{
    background-color: rgb(35, 36, 36);
    width: 83%;
    height: 10%;
    z-index: 1;
    border-radius: 10px;
    justify-content: flex-end;
    align-items: center;
    padding-top: 5px;
    overflow-x: auto;
  }

  .image-list-item {
    justify-content: center;
    width: 50px; /* Set the desired width */
    height: 50px; /* Set the desired height */
    margin: 5px; /* Add spacing between images */
    border: 1px solid #fff; /* Add a border or other styling as needed */
  }

  .image-list-scroll {
    display: flex;
    //height: 80%;
    flex-wrap: nowrap; /* Prevent images from wrapping to the next line */
    padding: 5px; /* Add spacing between images */
  }
  /* Thêm đường viền màu xanh cho ảnh đã chọn */
.image-list-item.selected {
  border: 2px solid #068ef0;
}
